import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

const Defend = () => {
  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO title="DEFEND" description="DEFEND" />
        <div className="dmd-22">
          <div className="dmd-22__section">
            <div className="dmd-22__container">
              <div className="dmd-22__grid reverse">
                <div className="dmd-22__hero-content">
                  <img
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2022-dymd-logo"
                    alt="image collage"
                    className="dmd-22__logo"
                  />
                  <h1 className="dmd-22__h1">
                    DEFENDING YOUNG SMILES WITH FREE MOUTH GUARDS
                  </h1>
                  <h2 className="dmd-22__h2">
                    Athletes are 60 times more likely to suffer from dental
                    trauma if they are not wearing a mouth guard.{" "}
                  </h2>
                  {/* <h3 className="dmd-22__subheading">
                    Join Us in Defending Young Smiles!
                  </h3> */}
                  <p>
                    Did you know that 60% of dental injuries can be eliminated
                    by wearing a mouth guard? Many sports and activities that
                    involve the risk of injury require mouth guards to protect
                    the teeth, gums, mouth, and jaw from severe and costly
                    dental trauma.
                  </p>
                  <h3 className="dmd-22__subheading">
                    Thank you to all those who attended Defend Your Mouth Day!
                    We look forward to seeing you next year!{" "}
                  </h3>
                  {/* <p>
                    Our Defend Your Mouth Day event will provide children and
                    teens with FREE, professionally fitted mouth guards! Join us
                    on Friday, July 29, in Amarillo!
                  </p> */}
                  {/* <a
                    href="https://calendly.com/secureform/full-smile-orthodontics-defend-your-mouth-day-2022?month=2022-07"
                    target="_blank"
                    className="dmd-22__btn">
                    Schedule Appointment
                  </a> */}
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2022-dymd-image-1"
                    alt="image collage"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="dmd-22__section dmd-22__color-back">
            <div className="dmd-22__container">
              <div className="dmd-22__grid vcenter">
                <div>
                  <h3 className="dmd-22__subheading">DID YOU KNOW?</h3>
                  <p>
                    More than 5 million teeth are knocked out each year, often
                    due to sports-related injuries. Remember, damaged teeth do
                    not grow back. Wearing a properly fitted mouth guard is an
                    easy way to protect teeth. In fact, mouth guards help avoid
                    200,000 oral injuries every year.
                  </p>
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2022-dymd-image-2"
                    alt="image collage"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="dmd-22__section">
            <div className="dmd-22__container">
              <div className="dmd-22__grid reverse vcenter">
                <div>
                  <h3 className="dmd-22__subheading">
                    The American Dental Association (ADA) recommends wearing a
                    mouth guard for the following sports:
                  </h3>

                  <div className="dmd-22__sports-list">
                    <ul>
                      <li>Soccer</li>
                      <li>Football</li>
                      <li>Softball</li>
                      <li>Gymnastics</li>
                      <li>Acrobatics</li>
                      <li>Equestrian events</li>
                      <li>Basketball</li>
                      <li>Ice hockey</li>
                      <li>Bicycling</li>
                    </ul>
                    <ul>
                      <li>Skiing</li>
                      <li>Extreme sports</li>
                      <li>Boxing</li>
                      <li>Field events</li>
                      <li>Volleyball</li>
                      <li>Handball</li>
                      <li>Inline skating</li>
                      <li>Lacrosse</li>
                      <li>Field hockey</li>
                    </ul>
                    <ul>
                      <li>Rugby</li>
                      <li>Water polo</li>
                      <li>Martial arts</li>
                      <li>Wrestling</li>
                      <li>Shot put</li>
                      <li>Racquetball</li>
                      <li>Skateboarding</li>
                      <li>Weight lifting</li>
                      <li>Squash</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2022-dymd-image-3"
                    alt="image collage"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="dmd-22__section dmd-22__color-back">
            <div className="dmd-22__container">
              <div className="dmd-22__grid">
                <div>
                  <h3 className="dmd-22__subheading">
                    Student athletes ages 8–18 and their families are welcome to
                    attend. We look forward to seeing you at this
                    family-friendly event promoting fun, safety, and good oral
                    health.
                  </h3>
                  <a
                    href="https://calendly.com/secureform/full-smile-orthodontics-defend-your-mouth-day-2022?month=2022-07"
                    target="_blank"
                    className="dmd-22__btn">
                    Schedule Appointment
                  </a>
                </div>
                <div>
                  <p>
                    <b className="dmd-22__color-blue">Date:</b> <br />
                    Friday, July 29, 2022
                  </p>

                  <p>
                    <b className="dmd-22__color-blue">Time:</b> <br />
                    8:00 AM&ndash;11:30 AM
                  </p>
                  <p>
                    <b className="dmd-22__color-blue">Location:</b> <br />
                    Full Smile Orthodontics <br />
                    7901 SW 45th Ave <br />
                    Amarillo, TX 79119
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default Defend
